import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import styles from '../../../styles/PublicTender/styles.module.scss';

import {ReactComponent as Download} from '../../../assets/icons/download.svg';
import {ReactComponent as Pencil} from '../../../assets/icons/pencil.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useNavigate} from 'react-router-dom';
import Button from "../../../components/Button/Button";
import Pagination from "../../../components/Pagination/Pagination";
import {IGetInstitutions, IInstitutionResponse} from "../../../ts/interfaces/Institution/interface";
import {deleteInstitution, getInstitutions, serializeArray} from "../../../services/services";
import {BarLoader} from "react-spinners";
import {getToken} from "../../../services/auth";
import {queryClient} from "../../../services/queryClient";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {toast} from "react-toastify";

const Institution: React.FC = () => {
    const [filter, setFilter] = useState<IGetInstitutions>({
        institution: "",
        page: 1,
        limit: 50
    });
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number | null>(null);

    const navigate = useNavigate();

    const {data, isLoading} = useQuery<IInstitutionResponse>(['institutions', filter], () => getInstitutions(filter), {
        staleTime: Infinity,
    });

    const handleDownloadCSV = () => {
        let link = document.getElementById('download_csv');
        link?.click();
    };

    const mutateDeleteInstitution = useMutation(() => {
        return deleteInstitution(id as number);
    }, {
        onSettled: (error: any) => {
            queryClient.invalidateQueries(['institutions', filter]);
            if(error){
                toast.error(error.response.data.error)
            }
            else {
                toast.success('Deletado com sucesso');
            }
            setOpen(false);
        },
        onError: () => {
                toast.error('Erro ao deletar a instituição');
        }
    })

    return (
        <div className={styles.container}>
            <h1>Instituições</h1>

            <Formik initialValues={{institution: ''}}
                    onSubmit={(values) => {
                        setFilter({...filter, institution: values.institution, page: 1});
                    }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="institution"
                            type="text"
                            placeholder="Pesquisar por nome ou sigla"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="institution" component="div" className={styles.error}/>
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >

                    CSV
                </Button>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => navigate('/institution/create')}
                    disabled={isLoading}
                >
                    Cadastrar
                </Button>
            </div>
            <div className={styles.dNone}>
                <a id="download_csv" rel="noopener noreferrer"
                   href={process.env.REACT_APP_API_URL + "/v1/institution/export?" + serializeArray(filter) + '&token=' + getToken()}
                   target="_blank" download></a>
            </div>


            <div className={styles.tableProducts}>
                {isLoading ?
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <>
                        {data && data.data.institutions.length > 0 ?
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>Sigla</th>
                                    <th>É Instituição</th>
                                    <th>Dt Cadastro</th>
                                    <th>Concursos</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.institutions.map((institution) => (
                                    <tr key={institution.id}>
                                        <td><img className={styles.icon} src={institution.url_icon}
                                                 alt="Ícone da carreira"/></td>
                                        <td>{institution.name}</td>
                                        <td>{institution.acronym}</td>
                                        <td>{institution.is_institution ? "Sim" : "Não"}</td>
                                        <td>{institution.created_at}</td>
                                        <td>
                                            <button className={styles.publicTenderCount}
                                                    onClick={() => navigate(`/public-tenders?institution=${institution.id}`)}>
                                                {institution.public_tender_count}
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className={styles.editProducts}
                                                onClick={() => navigate(`/institution/create/${institution.id}`)}
                                            >
                                                <Pencil/>
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    setId(institution.id);
                                                    setOpen(true);
                                                }}
                                                className={styles.editProducts}
                                            >
                                                <Trash/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <div className={styles.alignCenter}><span>Nenhum registro encontrado.</span></div>
                            )}
                    </>
                }
            </div>
            {data &&
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) => setFilter({...filter, page: payload})}
                />
            }

            {open && id &&
                <ModalConfirm propsFunction={mutateDeleteInstitution.mutate} open={open} setOpen={setOpen}
                              loading={mutateDeleteInstitution.isLoading}
                            >
                    <span>Essa ação é irreversível!</span>
                    <span>Tem certeza que deseja deletar a instituição?</span>
                </ModalConfirm>
            }
        </div>
    );
};

export default Institution;
