export const stylesSelect = {
    control: (styles: any) => ({
        ...styles,
        width: "100%",
        height: "37px",
    }),
    indicatorsContainer: (styles: any) => ({
        ...styles,
        height: "37px",
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        height: "37px",
        display: "flex",
        alignItems: "center",
        padding: "0px 8px",
    }),
    dropdownIndicator: (styles: any) => ({
        ...styles,
        padding: "4px",
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        padding: "4px",
    }),
};