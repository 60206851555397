import React, {useEffect, useState} from 'react';
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import arrow from "../../../../assets/icons/arrow-left.svg";
import {ReactComponent as Upload} from "../../../../assets/icons/upload.svg";
import Button from "../../../../components/Button/Button";
import {Form, Formik} from "formik";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useParams} from "react-router-dom";
import {ICareer, IPostCareers} from "../../../../ts/interfaces/Career/interface";
import {useMutation, useQuery} from "react-query";
import {getCareer, storeCareer} from "../../../../services/services";
import {BarLoader} from "react-spinners";
import Seo from "../../../../components/Seo/Seo";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import {TypeSeo} from "../../../../Enums/TypeSeo";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import success = toast.success;

const CreateCareer: React.FC = () => {

    const [seoPages, setSeoPages] = useState<IPostSeo[]>([])
    const [fileName, setFileName] = useState<string>("");

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    };

    const handleDescriptionChange = (value: string) => {
        setParams({...params, short_description: value});
    };

    const queryParams = useParams();

    const {data, isLoading} = useQuery<{
        data: ICareer
    }>(['career', queryParams?.id], () => getCareer(parseInt(queryParams?.id ?? '0')), {
        staleTime: Infinity,
        enabled: !!queryParams?.id
    })

    const [params, setParams] = useState<IPostCareers>({
        id: data && data.data ? data.data.id : '',
        url_icon: data && data.data ? data.data.url_icon : '',
        name: data && data.data ? data.data.name : '',
        short_description: data && data.data ? data.data.short_description : '',
        seo_pages: seoPages
    })

    useEffect(() => {
        if (data && data.data) {
            setParams({
                ...params,
                id: data.data.id,
                name: data.data.name,
                url_icon: data.data.url_icon,
                short_description: data.data.short_description,
            })
        }
    }, [data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({...params, [e.target.name]: e.target.value})
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];
        if (file) {
            setParams((prevParams) => ({
                ...prevParams,
                [id]: file,
            }));
            setFileName(file.name);
        }
    }

    const onSubmit = () => {
        if (!params.name || params.name.trim() === '') {
            toast.error('Preencha o nome')
            return
        }

        mutate.mutate(params)
    }

    const mutate = useMutation((data: IPostCareers) => {
        return storeCareer(data);
    }, {
        onSettled: (response: any) => {

            if (response?.data?.success) {
                toast.success('Salvo com sucesso!')

                setTimeout(() => {
                    window.location.href = '/careers'
                }, 800)
            } else {
                const errorMessage = response?.response?.data?.message || 'Erro ao salvar a carreira'
                toast.error(errorMessage)
            }
        },
        onError: (error) => {
            toast.error('Erro ao salvar a carreira')
        }
    })

    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages
        })
    }, [seoPages]);

    return (
        <>
            <Formik initialValues={{name: ''}}
                    onSubmit={() => onSubmit()}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a href='/careers' className={styles.btnReturn}><img src={arrow} alt="Voltar"/> Voltar</a>
                            <h1>{params.id ? 'Editar' : 'Cadastrar'} Carreira</h1>
                        </div>
                        {isLoading ?
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                            :
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>Informe os dados da Carreira</p>
                                    <div className={styles.groupData}>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="short_description">Descrição Curta</label>
                                                <ReactQuill
                                                    value={params.short_description}
                                                    onChange={handleDescriptionChange}
                                                    modules={modules}
                                                    theme="snow"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="url_icon">
                                                    Ícone
                                                </label>
                                                <div
                                                    className={styles.fileInput}
                                                >
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        onChange={(e) => {
                                                            handleFileChange(e, "url_icon");
                                                        }}
                                                        className={styles.input}
                                                        type="file"
                                                        name="url_icon"
                                                        id="url_icon"
                                                    />
                                                    {fileName ? (
                                                        <span>
                                                            {" "}
                                                            <Upload/>{" "}
                                                            <p>{fileName}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Upload/> <p>Faça o
                                                            upload do ícone</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {typeof params.url_icon ===
                                                "string" && (
                                                    <div
                                                        className={
                                                            styles.urlNoticeContainer
                                                        }
                                                    >
                                                        Arquivo:{" "}
                                                        <a
                                                            href={params.url_icon}
                                                            className={
                                                                styles.urlNotice
                                                            }
                                                        >
                                                            {params.url_icon}
                                                        </a>
                                                    </div>
                                                )}
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">Nome</label>
                                                <input disabled={mutate.isLoading}
                                                       className={styles.input}
                                                       value={params.name}
                                                       onChange={(e) => handleChange(e)}
                                                       type="text"
                                                       name="name"
                                                       id="name"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button disabled={mutate.isLoading || (mutate.isSuccess && mutate.data?.data?.success === false)} width="175px"
                                                height="50px"
                                                type="submit">Salvar</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Form>
            </Formik>
            <ToastContainer/>
        </>
    );
};

export default CreateCareer;