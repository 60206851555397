import React, {useEffect, useState} from 'react';
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import arrow from "../../../../assets/icons/arrow-left.svg";
import {ReactComponent as Upload} from "../../../../assets/icons/upload.svg";

import Button from "../../../../components/Button/Button";
import {Form, Formik} from "formik";
import {BarLoader} from "react-spinners";
import {toast, ToastContainer} from "react-toastify";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {
    getExaminingBoard,
    storeExaminingBoard,
} from "../../../../services/services";
import {IExaminingBoard, IPostExaminingBoards} from "../../../../ts/interfaces/ExaminingBoard/interface";
import Seo from "../../../../components/Seo/Seo";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import {TypeSeo} from "../../../../Enums/TypeSeo";

const RegisterExaminingBoard: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([])
    const [fileName, setFileName] = useState<string>("");

    const queryParams = useParams();

    const {data, isLoading} = useQuery<{
        data: IExaminingBoard
    }>(['examining-board', queryParams?.id], () => getExaminingBoard(parseInt(queryParams?.id ?? '0')), {
        staleTime: Infinity,
        enabled: !!queryParams?.id
    })

    const [params, setParams] = useState<IPostExaminingBoards>({
        id: data && data.data ? data.data.id : '',
        url_icon: data && data.data ? data.data.url_icon : '',
        name: data && data.data ? data.data.name : '',
        acronym: data && data.data ? data.data.acronym : '',
        seo_pages: seoPages
    })

    useEffect(() => {
        if (data && data.data) {
            setParams({
                ...params,
                id: data.data.id,
                url_icon: data.data.url_icon,
                name: data.data.name,
                acronym: data.data.acronym
            })
        }
    }, [data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({...params, [e.target.name]: e.target.value})
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];
        if (file) {
            setParams((prevParams) => ({
                ...prevParams,
                [id]: file,
            }));
            setFileName(file.name);
        }
    }

    const onSubmit = () => {
        if (!params.acronym || params.acronym.trim() === '') {
            toast.error('Preencha a sigla')
            return
        }

        if (!params.name || params.name.trim() === '') {
            toast.error('Preencha o nome')
            return
        }

        const formData = new FormData();
        formData.append("id", params.id.toString());
        formData.append('name', params.name || "");
        formData.append('acronym', params.acronym || "");
        if(params.url_icon instanceof File){
            formData.append('url_icon', params.url_icon);
        }
        formData.append("seo_pages", JSON.stringify(seoPages));

        mutate.mutate(formData);
    }

    const mutate = useMutation((data: FormData) => {
        return storeExaminingBoard(data);
    }, {
        onSettled: (response) => {
            if (response?.data?.success) {
                toast.success('Salvo com sucesso!')

                setTimeout(() => {
                    window.location.href = '/examining-boards'
                }, 800)

            } else {
                toast.error('Erro ao salvar a instituição')
            }
        },
        onError: (error) => {
            toast.error('Erro ao salvar a instituição')
        }
    })


    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages
        })
    }, [seoPages]);

    return (
        <>
            <Formik initialValues={{name: '', acronym: ''}}
                    onSubmit={() => onSubmit()}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a href='/examining-boards' className={styles.btnReturn}><img src={arrow}
                                                                                          alt="Voltar"/> Voltar</a>
                            <h1>{params.id ? 'Editar' : 'Cadastrar'} Banca</h1>
                        </div>
                        {isLoading ?
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                            :
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>Informe os dados da banca</p>
                                    <div className={styles.groupData}>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="url_icon">
                                                    Ícone
                                                </label>
                                                <div
                                                    className={styles.fileInput}
                                                >
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        onChange={(e) => {
                                                            handleFileChange(e, "url_icon");
                                                        }}
                                                        className={styles.input}
                                                        type="file"
                                                        name="url_icon"
                                                        id="url_icon"
                                                    />
                                                    {fileName ? (
                                                        <span>
                                                            {" "}
                                                            <Upload/>{" "}
                                                            <p>{fileName}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Upload/> <p>Faça o
                                                            upload do ícone</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {typeof params.url_icon ===
                                                "string" && (
                                                    <div
                                                        className={
                                                            styles.urlNoticeContainer
                                                        }
                                                    >
                                                        Arquivo:{" "}
                                                        <a
                                                            href={params.url_icon}
                                                            className={
                                                                styles.urlNotice
                                                            }
                                                        >
                                                            {params.url_icon}
                                                        </a>
                                                    </div>
                                                )}
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="acronym">Sigla</label>
                                                <input disabled={mutate.isLoading}
                                                       value={params.acronym}
                                                       onChange={(e) => handleChange(e)}
                                                       className={styles.input} type="text"
                                                       name="acronym"
                                                       id="acronym"/>
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">Nome</label>
                                                <input disabled={mutate.isLoading}
                                                       className={styles.input}
                                                       value={params.name}
                                                       onChange={(e) => handleChange(e)}
                                                       type="text"
                                                       name="name"
                                                       id="name"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button disabled={mutate.isLoading || mutate.isSuccess} width="175px"
                                                height="50px"
                                                type="submit">Salvar</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Form>
            </Formik>
            <ToastContainer/>
        </>
    );
};

export default RegisterExaminingBoard;
